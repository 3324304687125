import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import "./styles.scss"
import PaymentManagement from "../../domain/paymentManagement"

const index = () => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout selectedPage={<PaymentManagement />} />
    </div>
  )
}

export default index
