import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons"
import { Col, Form, Row, Tag, Space } from "antd"
import React, { useState, useEffect, useCallback, useContext } from "react"
import { AccountContext } from "../../context/account"
import { createNp, getCustomerNp } from "../../services/user"

const dataStatus = {
  "01": {
    text: "未申請",
    icon: <CloseCircleOutlined />,
    color: "error",
  },
  "02": { text: "審査中", icon: <SyncOutlined spin />, color: "processing" },
  "03": {
    text: "審査完了",
    icon: <CheckCircleOutlined />,
    color: "success",
  },
  10: {
    text: "保留",
    icon: <ExclamationCircleOutlined />,
    color: "warning",
  },
}

function Payment() {
  const { user } = useContext(AccountContext)
  const [npStatus, setNpStatus] = useState("01")
  const [amountLimit, setAmountLimit] = useState(0)

  const getPayment = useCallback(async () => {
    const { data } = await getCustomerNp()
    if (!data.data.results) {
      const { data } = await createNp()
      if (data.data.statusInfo) {
        setNpStatus(data.data.statusInfo?.buyerAuthori?.status || "01")
        setAmountLimit(data.data.statusInfo?.authoriFacility?.amountLimit)
      }
    } else {
      setNpStatus(
        data.data?.results?.[0]?.statusInfo?.buyerAuthori?.status || "01"
      )
      setAmountLimit(data.data.statusInfo?.authoriFacility?.amountLimit)
    }
  }, [])

  useEffect(() => {
    getPayment()
  }, [getPayment])

  return (
    <div className=" mx-auto p-[50px] bg-[#fff] myContainer">
      <Form>
        <div
          className="flex items-center py-4"
          style={{
            padding: npStatus != 10 ? "16px 0" : "16px 0 0 0",
            borderBottom:
              npStatus == 10 ? "none" : "1px solid rgba(0, 0, 0, 0.06)",
          }}
        >
          <Row gutter={6} className="w-full">
            <Col className="gutter-row col-text" span={6}>
              <Tag color="red">必須</Tag>
              <span className="block ml-2 payment-text">NP掛け払い</span>
            </Col>
            <Col className="gutter-row col-center" span={18}>
              <Space>
                <Tag
                  color={dataStatus[npStatus].color}
                  icon={dataStatus[npStatus].icon}
                  className="!text-base !rounded-sm !flex !items-center"
                >
                  {dataStatus[npStatus].text}
                </Tag>
                {npStatus == "03" && (
                  <Tag
                    color={dataStatus["03"].color}
                    className="!text-base !rounded-sm !flex !items-center"
                  >
                    与信枠：{new Intl.NumberFormat("ja-JP").format(amountLimit)}
                    円
                  </Tag>
                )}
              </Space>
            </Col>
          </Row>
        </div>
        {npStatus == 10 && (
          <div
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
              textAlign: "center",
              padding: "8px 0",
            }}
          >
            情報不備にて審査保留となった場合サポートデスクよりご連絡いたします。
          </div>
        )}
        <div
          className="flex items-center py-4"
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
        >
          <Row gutter={6} className="w-full">
            <Col className="gutter-row col-text" span={6}>
              <Tag color="red">必須</Tag>
              <span className="block ml-2 payment-text">
                GMOペイメントクレジットカード
              </span>
            </Col>
            <Col className="gutter-row col-center" span={16}>
              <Tag
                color="blue"
                className="!text-base !text-[#2F54EB] !rounded-sm"
              >
                再申請
              </Tag>
            </Col>
          </Row>
        </div>
        <div
          className="flex items-center py-4"
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
        >
          <Row gutter={6} className="w-full">
            <Col className="gutter-row col-text" span={6}>
              <Tag color="red">必須</Tag>
              <span className="block ml-2 payment-text">メールアドレス</span>
            </Col>
            <Col className="gutter-row col-center" span={16}>
              <Tag
                color="blue"
                className="!text-base !text-[#2a51f0] !rounded-sm"
              >
                {user?.email}
              </Tag>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}

export default Payment
